import React, { useState, useEffect } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import BlogContent from '../components/Blog/BlogContent'
import { navigate } from 'gatsby'
import { getPosts } from '../api/post'
import { getTime } from '../utils/get-time'
import { getSettings } from '../api/settings'

const Blog = ({ location }) => {
    const [posts, setPosts] = useState(null)
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState(null)
    const [settings, setSettings] = useState(null)

    const params = new URLSearchParams(location.search)
    const category = params.get('category')

    useEffect(() => {
        (async () => {
           
            if (getTime() > 5 || (!localStorage.getItem('settings') || localStorage.getItem('settings')=== '' || localStorage.getItem('settings') === '[]')) {
                try {
                    setSettings(null)
                    const data = await getSettings()
                    localStorage.setItem('settings', JSON.stringify(data))
                    setTimeout(() => {
                        localStorage.setItem('time', new Date().getTime())
                      }, "15000");
                    setSettings(data)
                } catch (error) {
                    setSettings(null)
                }
               
            } else {
              
                setSettings(JSON.parse(localStorage.getItem('settings')))
            }
        })()
    }, [])
    useEffect(() => {
        (async () => {
            try {
                setPagination(null)
                setPosts(null)
                const data = await getPosts(category, page)
                setPagination(data)
                setPosts(data.data)
            } catch (error) {
                setPagination(null)
                setPage(1)
                setPosts(null)
                navigate('/404')
            }
        })()
    }, [category, page])

    if (!posts) return null

    return (
        <Layout title={settings?.find(x => x.key === 'page.news.title') ? settings.find(x => x.key === 'page.news.title').value : 'Noticias'}>
            <PageBanner
                pageTitle={settings?.find(x => x.key === 'page.news.title') ? settings.find(x => x.key === 'page.news.title').value : 'Noticias'}
                homePageText='Inicio'
                homePageUrl='/'
                activePageText={settings?.find(x => x.key === 'page.news.title') ? settings.find(x => x.key === 'page.news.title').value : 'Noticias'}
            />
            <BlogContent
                posts={posts}
                pagination={pagination}
                setPage={setPage}
            />
        </Layout>
    )
}

export default Blog
