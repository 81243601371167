import React from "react";
import BlogCard from "./BlogCard";
import FirstBlogCard from "./FirstBlogCard";
import Pagination from "react-js-pagination";

const BlogContent = ({ posts, pagination, setPage }) => {
    const { current_page, per_page, total } = pagination;
    const welcome_info = JSON.parse(localStorage.getItem("settings")).find((x) => x.key === "blog.welcome_info")?.value;

    return (
        <section className="blog-area ptb-50">
            <div className="container py-5">
                <div className="row">
                    {welcome_info ? (
                        <div className="col-12">
                            <div className="single-blog-post bg-fffbf5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="post-content">
                                            <i className="bx-pull-left bx bx-info-circle bx-lg p-5"></i>
                                            <p className="mt-3">{welcome_info}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {posts.map((post, index) => {
                        return index === 0 ? (
                            <FirstBlogCard
                                key={post.id}
                                post={post}
                            />
                        ) : (
                            <BlogCard
                                key={post.id}
                                post={post}
                            />
                        );
                    })}
                    <Pagination
                        activePage={current_page ? current_page : 0}
                        itemsCountPerPage={per_page ? per_page : 0}
                        totalItemsCount={total ? total : 0}
                        onChange={(pageNumber) => {
                            setPage(pageNumber);
                        }}
                        pageRangeDisplayed={4}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </div>
            </div>
        </section>
    );
};

export default BlogContent;
